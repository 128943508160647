import { useCohortDate } from '@landing-blocks/shared/hooks/use-cohort-date/use-cohort-date.js';
import type { CountryType } from '@landing-blocks/shared/types/countries.js';
import type { FunctionalComponent } from 'preact';

import type { BlockProfession1Props } from './block-profession-1.js';
import { BlockProfession1 } from './block-profession-1.js';

export type BlockProfession1ContainerProps = Omit<BlockProfession1Props, 'cohortDate'> & {
    professionSlug: string;
    countrySlug: CountryType;
};

export const BlockProfession1Container: FunctionalComponent<BlockProfession1ContainerProps> = ({
    professionSlug,
    countrySlug,
    ...props
}) => {
    const { cohortDate } = useCohortDate(countrySlug, professionSlug);

    return <BlockProfession1 {...props} cohortDate={cohortDate} />;
};
